.App {
  text-align: center;
}

.App-logo {
  width: 100%;
  pointer-events: none;
  display: block;

  @media (min-width: 1000px) {
    width: 30rem;
    display: inline-block;
  }
}


.App-top-body {
  background-image: url("/images/cleaning-home.png");
  background-size: cover;
  background-position: 50% 50%;
  height: 52rem;

  .Top-body-content {
    position: sticky;
    top: 1rem;
    margin-bottom: 3rem;

    img {
      color: rgb(189, 189, 189);
      top: 1rem;
      text-align: center;
      padding-left: 50%;
      margin: auto;
      width: 11rem;
    }

    p {
      color: #d0ae66;
      padding-left: 50%;
      margin-top: -2rem;
      font-size: 0.875rem;
    }

    @media (min-width: 1200px) {
      img {
        padding-left: 84%;
      }

      p {
        padding-left: 84%;
      }
    }
  }
}

.App-main-body {
  background-color: #323232;
  min-height: 100vh;
color: white;
  .about-us {
    padding: 1rem 0.5rem;

    @media (min-width: 1200px) {
      padding: 1rem 15rem;
    }
  }

  .contact-div {
    position: relative;

    hr {
      width: 90%;
      border-color: #d0ae66;
    }

    h1 {
      font-weight: 300;
      width: auto;
      z-index: 5;
      position: absolute;
      top: 3.5rem;
      color: #d0ae66;
      margin: auto;
      left: 0;
      right: 0;

      @media (min-width: 1000px) {
        width: 15rem;
      }
    }

    .contact-title {
      width: 100%;
      display: block;
      position: relative;

      @media (min-width: 1000px) {
        width: 35%;
        display: inline-block;
      }
    }

    .ContactForm {
      margin-top: -4rem;
      margin-bottom: 3rem;
      width: 100%;
      display: block;
      position: relative;


      @media (min-width: 1000px) {
        margin: -3rem auto 3rem auto;
        width: 60%;
        display: block;
        vertical-align: top;
      }

      @media (min-width: 2500px) {
        margin-top: 2rem;
        width: 60%;
        display: inline-block;
        vertical-align: top;
      }


      .form-input-split {
        @media (min-width: 1000px) {
          width: 50%;
          display: inline-block;
          vertical-align: top;
        }

        .input-div {
          margin: 0.75rem auto;
          border: solid 2.5px;
          border-image: linear-gradient(to right, rgba(221, 175, 82, 0.74), rgba(221, 175, 82, 0.74), #dac786, rgba(233, 191, 106, 0.74), #fff6a7, #fff6a7) 5;
          width: 18rem;
          display: inline-block;

          .formInput {
            display: block;
            width: 16rem;
            background-color: transparent;
            margin: 0.35rem 0.25rem 0.35rem 0.35rem;
            padding: 0.5rem;
            color: rgb(189, 189, 189);
            font-size: 1.5rem;
            border: solid 2.5px;
            border-image: linear-gradient(to right, rgba(221, 175, 82, 0.74), rgba(221, 175, 82, 0.74), rgba(218, 199, 134, 1),rgba(233, 191, 106, 0.74), rgba(255, 246, 167, 1), rgba(255, 246, 167, 1)) 5;
          }

          @media (min-width: 1000px) {
            margin: 0.75rem 3rem 0rem 0rem;
            width: 20rem;

            &.high-columns {
              margin: 0.75rem 3rem 1.25rem 0rem;
            }

            .formInput {
              width: 18rem;
            }
          }
      }
    }
   }

   .submit-btn {
      background-color: transparent;
      color: #d0ae66;
      padding: 20px 90px;
      text-decoration: none;
      margin: 1rem 0rem 0rem 0rem;
      cursor: pointer;
      border: solid 2.5px;
      border-image: linear-gradient(to right, rgba(221, 175, 82, 0.74), rgba(221, 175, 82, 0.74), #dac786, rgba(233, 191, 106, 0.74), #fff6a7, #fff6a7) 5;
      font-size: 1rem;
      transition: all .5s ease-in;
      position: relative;
      top: 0.75rem;
      @media (min-width: 1000px) {
        margin: 2rem 0rem 0rem 0rem;
      }
    }

    .submit-btn:hover {
      background-color: #d0ae66;
      color: #323232;
    }

    .input-div.message-box {
      width: 33rem;
      margin: 0.75rem 2rem 0rem -4rem;

      .formInput {
        width: 31rem;
      }
    }

    .formInput::placeholder {
      color: rgb(189, 189, 189);
    }

    .formInput:focus {
      outline: none;
    }

    textarea {
      display: block;
      background-color: transparent;
      border-color: rgba(221, 184, 107, 0.52);
      padding: 0.5rem;
      color: rgb(189, 189, 189);
      font-size: 1.5rem;
      margin: 1rem;
    }

    textarea::placeholder {
      color: rgb(189, 189, 189);
    }
  }

  .App-logo {
    padding-top: 1rem;
  }

  .service-block {
    padding-top: 4rem;

    .service-wrapper {
      max-width: 90%;
      margin: auto;
      display: inline-block;
      vertical-align: top;
      // color: rgb(232, 193, 108);

      .service-icon {
        filter: brightness(0) invert(1);
        width: 6rem;
      }

      .service-text {
        padding: 1rem;
      }
    }

    @media (min-width: 1000px) {
      .service-wrapper {
        max-width: 30%;
      }
    }
  }

  .contact-footer {

    @media (min-width: 1000px) {
    }

    .social-icons {
      display: block;

      .social-icon {
        width: 40px;
      height: 40px;
      background: #fbfbfb;
      -moz-border-radius: 70px;
      -webkit-border-radius: 70px;
      border-radius: 70px;
      display: inline-block;
      margin: auto;
      margin-right: 1rem;
      margin-bottom: 1.25rem;
      }
    }
  }
}

.App-link {
  color: #61dafb;
}


.accordion {
  $midnight: #2c3e50;
  $clouds: #ecf0f1;
  padding-bottom: 2rem;;
  margin: auto;
  width: 48rem;
  margin-top: 3.5rem;
  display: inline-block;
  vertical-align: top;

  .no-visibility {
    visibility: hidden;
  }

  // General
  body {
    color: $midnight;
    background: $clouds;
    padding: 0 1em 1em;
  }
  h1 {
    margin: 0;
    line-height: 2;
    text-align: center;
  }
  h2 {
    margin: 0 0 .5em;
    font-weight: normal;
  }

  // Layout
  .row {
    display:flex;
    .col {
      flex:1;
      &:last-child {
        margin-left: 1em;
      }
    }
  }
  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0,0,0,0.5);
  }
  .tab {
    width: 100%;
    color: rgb(189, 189, 189);
    overflow: hidden;
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: $midnight;
      font-weight: bold;
      cursor: pointer;
      /* Icon */
      &:hover {
        background: darken($midnight, 10%);
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all .35s;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      color: $midnight;
      background: transparent;
      transition: all .35s;
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $midnight;
      cursor: pointer;
      &:hover {
        background: darken($midnight, 10%);
      }
    }
  }

  // :checked
  input:checked {
    + .tab-label {
      background: darken($midnight, 10%);
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 25rem;
      padding: 1em;
    }
  }
}

.center-text {
  text-align: center;
}

.display-inline {
  display: inline;
}

.center-right {
  text-align: right;
}

.center-left {
  text-align: left;
}

.desktop-padding-top {
  padding-top: 0;

  @media (min-width: 1000px) {
    padding-top: 3rem!important;
  }
}

.specialist-carousel {
  width: 70%;
  margin: auto;

  .test  {
    height: 225px; 
    @media (min-width: 1000px) {
    height: 150px;
    }
  }
  
  .flickity-prev-next-button {
    top: 40%; 

    @media (min-width: 1000px) {
      top: 50%; 
    }

    &.previous {
      left: -50px;

      @media (min-width: 1000px) {
        left: 10px;
      }  
    }

    &.next {
      right: -50px;

      @media (min-width: 1000px) {
        right: 10px
      }  
    }
  }
}

.padding-x-eight {
  padding: 0 0rem;

  @media (min-width: 1000px) {
    padding: 0 8rem;
  }
}

.slider-item {
  height: 325px;

  @media (min-width: 1000px) {
    height: 300px;
  }
}